<template>
  <div class="payment-page">
  <div class=" height-s-fix">
    <div class="card-info">
      <div class="info-wrapper">
        <div class="row No-padding">
          <div class="col-12 text-center">
            <img src="@/assets/images/logo.png" width="173px" height="92px" alt="" class="img-fluid">
            <p class="mb-2">EOI Booking</p>
            <h2 class="page-heading">Nirvana Bougainvilla</h2>
            <p class="align-data">Khopoli, Maharashtra</p>
          </div>
        <div class="col-12 px-0">
          <div class="paymnet-details">
            <div class="paymnet-details-title">Pay EOI</div>
            <div class="plan-body">
              <div class="payment-section">
                <span class="payment-title">Selected Variant </span>
                <span class="payment-value">{{selectedFloor.configuration}} - {{selectedFloor.area}} sqft</span>
              </div>
              <div class="payment-section row">
                <div class="col-12">
                  <span class="payment-title">Building</span>
                  <span class="payment-value">{{selectedTypology.key}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <label class="eoi payment-amount-label mt-3 mb-2  mob-hide">EOI Amount</label>
        <div class="col-12 payment-button-section px-0  mob-hide">
          <div class="col-8 px-0 ">
            <input type="text" class="payment-amount" name="payment-amount" readonly :value="totalAmount"/>
          </div>
          <div class="col-4 px-0">
            <button type="button" @click="onSubmit" class="btn btn-block payment-btn">Pay EOI</button>
          </div>
        </div>
        <div class="col-12 payment-button-section mob-show">
          <div class="mx-3 mx-lg-0">
            <label class="eoi payment-amount-label">EOI Amount</label>
           <input type="text" class="payment-amount" name="payment-amount" readonly :value="totalAmount"/>
            <button type="button" @click="onSubmit" class="btn btn-block payment-btn">Pay EOI</button>
          </div>
        </div>
        <div class="row back-section">
          <button @click="$router.push({name:'Unit', query:{...$route.query}})" type="button" class="prevButton">
            <img src="@/assets/images/back-arrow.svg" alt="">
                Back</button>
        </div>
      </div>
    </div>
  </div>
  <div class="progress-bar-container px-3 pb-3"><ProgressBar :steps="formSteps" :activeStepNo="4"/></div>
  <div class="col-12 powered-by powered-by-mob">Powered by <span class="powered-by-ppistol ml-1"> PropertyPistol&nbsp;Technologies</span></div>
  </div>
</div>
</template>

<script>
import {FormSteps} from '@/assets/scripts/utility.js';
import ProgressBar from "@/components/ProgressBar.vue";
export default {
  components: {
    ProgressBar
  },
  data() {
    return {
      formSteps:FormSteps,
    }
  },
  mounted(){
    this.$store.dispatch('setLoading',false);
  },
    methods: {
      onSubmit() {
        const urlParams = new URLSearchParams({
          typology:this.selectedTypology.uuid,
          configuration:this.selectedFloor.uuid,
          ...(this.userId ?{userId: atob(this.userId)}:{}),
          planId:this.planId

        });
        window.open(`${this.$axios.defaults.baseURL}bookings/new?${urlParams}`,'_self');
      }
    },
  computed: {
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    selectedWing(){
      return this.$store.getters.selectedWing;
    },
    amount(){
      return this.$store.getters.amount;
    },
    userId(){
      return this.$store.getters.userId
    },
    planId(){
      return this.$store.getters.planId
    },
    totalAmount(){
      return `₹ ${Number(this.amount).toLocaleString('en-IN')}`
    }
  }
}
</script>

<style scoped>

.eoi.payment-amount-label{
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: 500;
  margin-top: 30px;
  margin-left: 16px
}

  .height-s-fix{
    display: flex;
    flex-direction: column
  }

  .payment-page{
    background: #ffffff;
    width: 100%;
    height: 100vh;
  }
.back-section{
  padding-left: 30px;
}

.progress-bar-container{
  max-width: 960px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: auto;
}

.powered-by-mob{
  display: none;
}
.powered-by {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #00000080;
  margin-top: 25px;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}

.prevButton {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 13px;
  margin-top: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
}

.prevButton:hover{
  gap: 10px;
}

.paymnet-details{
  background: #FFFFFF;
  border: 1px solid rgba(73, 72, 153, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin-top: 30px;
  overflow: hidden;
}
.paymnet-details-title{
  padding:16px;
  background: rgba(43, 45, 66, 0.1);
  border-radius: 10px 10px 0px 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.payment-section{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 16px;
}

.payment-section:not(:last-child){
  border-bottom: 1px solid #DBDAEB;
}

.payment-section{
  font-size: 13px;
  line-height: 16px;
  color: #00000080;
}

.payment-button-section{
  display: flex;
}
.payment-title{
  display: block;
  margin-bottom: 6px;
}

.payment-value{
  color: #000000;
  font-weight: 600;
}

.payment-amount{
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-left: 16px;
  background: rgba(43, 45, 66, 0.1);
  width: 100%;
  border: none;
  padding-top: 15px;
  padding-bottom: 14px;
  color: #00000080;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  outline: none !important;
}

.payment-btn{
  background: #0075C4;
  border-radius: 0px 4px 4px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 100%;
  box-shadow: none;
  height: 100%;
}

.payment-btn:focus, .payment-btn.focus{
  box-shadow: none;
}
.payment-btn:hover, .payment-btn:active{
  color: #ffffff;
}
/* .container {
  background: #FFFFFF;
} */
.card-info {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 25px;
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 40px; /* Added */
}
.info-section-details {
    /* position: absolute; */
width: 100%;
display: flex;
align-items: center;
/* height: 83px; */
margin: 2px;
border: 1px solid rgba(73, 72, 153, 0.2);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
margin: 0px;
padding: 20px;
background: #0075C4;
opacity: 0.1;
border-radius: 10px 10px 0px 0px;

}
  .info-wrapper {
    /* background: #fff; */
    width: 100%;
    /* padding: 16px; */
    /* border-radius: 8px; */
    display: flex;
  }

  .info-wrapper h2,
  .amenities-section h2 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 6px;
  }

  .info-wrapper p {
    font-size: 13px;
    color: #000000;
    line-height: 18px;
    padding: 16px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-left: 0px;
    padding-bottom: 0px;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 18px;
    text-transform: none;
    opacity: 0.5;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }
 .mob-show{
  display: none;
 }

 @media (min-width:992px){
  .card-info{
    width: 35%;
    max-width: 460px;
  }
 }

 @media (max-width:991px){
  .mob-show{
    display: block;
  }
  .eoi.payment-amount-label{
    padding-left: 6px;
    margin-left: 0;
    margin-top: 16px
  }
  .prevButton{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  .card-info{
    padding: 0;
  }
  .back-section{
    width: 100%;
   }
  .info-wrapper .row{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .paymnet-details{
    margin: 0 16px;
  }
  .paymnet-details-title{
    border-radius: 0;
  }
  .mob-hide{
    display: none;
  }
  .payment-button-section{
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .payment-button-section label{
    padding-left: 16px;
  }
  .payment-btn{
    height: unset;
    width: unset;
    padding: 16px 50px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 4px;
  }

  .powered-by.powered-by-mob{
    display: block;
    position: static;
    margin: auto;
    text-align: center;
    transform: none;
    padding-top: 50px;
    background: #f6f6f6;
    padding-bottom: 50px;
  }

  .payment-amount{
    border-radius: 4px
  }

 }
</style>